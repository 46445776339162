.discipline-page {
    padding: 0px 20px 0px 20px;
}

.useful-files ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.useful-files li {
    margin-bottom: 10px;
    padding: 10px 15px;
    border: 1px solid rgba(0, 123, 255, 0.3);
    border-radius: 8px;
    background: linear-gradient(135deg, #4ecdc499, #00796b);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s;
}

.useful-files li:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, rgba(78, 205, 196, 0.8), rgba(106, 177, 175, 0.8));
}

.useful-files a {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    transition: color 0.3s;
}

.useful-files a:hover {
    text-decoration: none;
    color: #ffffff;
}

.name {
    font-family: 'Arial', sans-serif;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 8px;
    padding: 5px;
}

.header {
    background-color: #2c3e50;
    color: #ecf0f1;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.nav {
    display: flex;
    justify-content: center;
}

.nav-buttons,
.courses {
    list-style-type: none;
    padding: 0;
    display: flex;
}

.nav-buttons li,
.courses li {
    cursor: pointer;
    margin: 0 10px;
    padding: 10px 20px;
    position: relative;
    border-radius: 5px;
    color: #fff;
    background: linear-gradient(135deg, #3498db, #2980b9);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.nav-buttons li:hover,
.courses li:hover {
    background: linear-gradient(135deg, #2980b9, #3498db);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.courses li.active {
    background: linear-gradient(135deg, #2980b9, #3498db);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.semester-list {
    padding: 10px;
    width: 200px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #ecf0f1;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.semester-list h3 {
    margin-top: 0;
    color: #2c3e50;
}

.semester-list ul {
    list-style-type: none;
    padding: 0;
}

.semester-list li {
    cursor: pointer;
    padding: 8px 12px;
    color: #2c3e50;
}

@media (max-width: 1024px) {
    .nav-buttons li,
    .courses li {
        cursor: pointer;
        margin: 0 5px;
        padding: 10px 15px;
    }

    .semester-list {
        width: 150px;
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .nav {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-buttons,
    .courses {
        cursor: pointer;
        list-style-type: none;
        padding: 0;
        display: flex;
        margin-top: -5px;
    }

    .semester-list {
        width: 130px;
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .nav-buttons li,
    .courses li {
        cursor: pointer;
        margin: 0 3px;
        padding: 8px 12px;
        font-size: 12px;
    }

    .semester-list {
        width: 120px;
        font-size: 10px;
    }
}

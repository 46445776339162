@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.course-page {
    padding: 0px 20px 0px 20px;
}

.course-info {
    background-color: #e0f7fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px;
    margin: 20px auto;
    max-width: 800px;
    color: #333;
    max-height: 400px;
    overflow-y: auto;
    font-family: 'Roboto', sans-serif;
    line-height: 1.2;
    font-size: 16px;
}

@media (max-width: 768px) {
    .course-info {
        max-width: 90%;
        max-height: 300px;
    }
}

@media (max-width: 480px) {
    .course-info {
        max-width: 95%;
        max-height: 250px;
    }
}

.course-info h3 {
    color: #00796b;
    margin-top: 0px;
}

.course-semester-list {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.course-semester-list ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.course-semester-list li {
    cursor: pointer;
    margin: 0 10px;
    padding: 12px 50px;
    border-radius: 8px;
    background: linear-gradient(135deg, #4ecdc4, #6ab1af);
    color: #fff;
    font-size: 16px;
    text-align: center;
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.course-semester-list li:hover {
    background: linear-gradient(135deg, #6ab1af, #4ecdc4);
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.course-semester-list h3 {
    margin-top: 0;
    font-size: 18px;
    color: #495057;
    text-align: center;
    margin-bottom: 10px;
}

.course-semester-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.course-semester-list li {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.course-plan {
    margin-top: 20px;
}

.course-plan ul {
    list-style-type: none;
    padding: 0;
    justify-content: center;
}

.course-plan li {
    margin: 10px 0;
}

.course-plan button {
    cursor: pointer;
    padding: 12px 160px;
    border-radius: 8px;
    background: linear-gradient(135deg, #00796b, #004d40);
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.course-plan button:hover {
    background: linear-gradient(135deg, #004d40, #00796b);
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
    .course-plan button {
        padding: 10px 100px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .course-plan button {
        padding: 8px 80px;
        font-size: 16px;
    }
}

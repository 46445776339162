.practice-page {
    padding: 0px 20px 0px 20px;
    font-family: Arial, sans-serif;
}

.practice-info {
    margin-bottom: 20px;
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.practice-info h3 {
    margin-top: 0;
}

.enterprise-list {
    max-width: 820px;
    margin: 20px auto;
}

.enterprise-list ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.enterprise-list li {
    cursor: pointer;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: linear-gradient(135deg, #4ecdc4, #6ab1af);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s;
    color: #ffffff;
    font-size: 18px;
    font-weight: bolder;
}

.enterprise-list li:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, rgba(78, 205, 196, 0.8), rgba(106, 177, 175, 0.8));
}

.enterprise-list li:active {
    transform: translateY(-2px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.enterprise-list li span {
    font-size: 18px;
    font-weight: bolder;
    color: #ffffff;
}

.enterprise-list li .icon {
    font-size: 24px;
    color: #ffffff;
    transition: color 0.3s;
}

.enterprise-list li:hover .icon {
    color: #cccccc;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
}

.App header {
  width: 100%;
  background-color: #333;
  color: #fff;
}

.App main {
  flex: 1;
  width: 100%;
  background-color: #f9f9f9;
}

.App footer {
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 5px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .App footer {
    padding: 5px 0;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .App footer {
    padding: 5px 0;
    font-size: 14px;
  }
}

.link {
  color: #3498db;
}
